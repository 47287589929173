import React from "react"
import { Box, Flex } from "theme-ui"
import { MagicLink } from "../../../utils/magicLink"
import { OutboundLink } from "../link"
import { useSocial } from "../../../hooks/useSocial"

const Footer = ({ block }) => {
  const {
    privacy,
    cookie,
    credits,
    legalInfo,
  } = block

  const social = useSocial()

  return (
    <Box sx={{}}>
      <Flex
        sx={{
          justifyContent: "space-between",
          ul:{
            li:{
              pb:[2],
              color:"dark",
              fontWeight:"500",
              a:{
                color:"dark"
              }
            }
          }
        }}
      >
        <Box
          as="ul"
          sx={{
            listStyleType: "none",
            display:!social && "flex",
            li:{
              mr: !social && [2]
            },
            p: 0,
          }}
        >
          <Box as="li">
            <MagicLink item={privacy}>Privacy</MagicLink>
          </Box>
          <Box as="li">
            <MagicLink item={cookie}>Cookie</MagicLink>
          </Box>
          <Box as="li">
            <MagicLink item={credits}>Credits</MagicLink>
          </Box>
        </Box>
        {console.log("social",social)}
        {social && (
          <Box
          as="ul"
            sx={{
              listStyleType: "none",
              p: 0,
            }}
          >
            {social.facebook && (
              <Box as="li">
                <MagicLink target="_blank" href={social.facebook}>
                  Facebook
                </MagicLink>
              </Box>
            )}
            {social.instagram && (
              <Box as="li">
                <MagicLink target="_blank" href={social.instagram}>
                  instagram
                </MagicLink>
              </Box>
            )}
            {social.youtube && (
              <Box as="li">
                <MagicLink target="_blank" href={social.youtube}>
                  YouTube
                </MagicLink>
              </Box>
            )}
          </Box>
        )}
      </Flex>
      <Box
        sx={{
          pt: [3],
          pb: [4],
        }}
        dangerouslySetInnerHTML={{ __html:legalInfo}}
      />
    </Box>
  )
}

export default Footer
